import React, { Component } from 'react';
import Layout from'./hoc/Layout/Layout'
import File from './containers/File/File'
import Home from './components/home'
import Header from './components/header'
import Footer from './components/footer'
import { Routes, Route } from 'react-router-dom';

import './App.css';

class App extends Component{
    render() {
        return (
            <Layout>
                <Header />
                <article>
                    <Routes>
                        <Route path="/" exact element={<Home />} />
                        <Route path="/media" element={<File />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </article>
                <Footer />
            </Layout>
        )
    }
}

export default App
