export const getImgMeta = async(url) => {
    const img = new Image();
    img.src = url;
    await img.decode();
    return img
}

export const getFiledata = async(url) => {

    let link = '/fileData'

    const urlData = {}
    urlData.url = url
    const recursiveEncoded = new URLSearchParams(urlData);
    link += `?${recursiveEncoded}`

    return fetch(link, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(resp => resp.json())
        .then(resp => {
            return resp
        })
}