
const FileCard = props => {
    const cardStyle = {
        marginTop: '30px',
    }
    let descr = ''
    if(props.description) descr = <p className="card-text">{ props.description }</p>
    return (
        <div className="card" style={ cardStyle }>
            <div className="card-body">
                <h5 className="card-title">{ props.name }</h5>
                {descr}
            </div>
        </div>
    )
}

export default FileCard
