import React, { Component} from 'react';

class Video extends Component {

    componentDidMount() {
        const videoElem = document.getElementById('video')
        videoElem.load();
    }

    render() {
        return (
            <div className="text-center">
                <video id="video" className="file-source"
                       preload={true.toString()}
                       src={this.props.src}
                       controls
                       autoPlay
                       controlsList="nodownload"
                       type="video/mp4"
                       style={{ width: "100%"}} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    document.getElementById('file-container').className = 'img-onerror'
                }}/>
            </div>
        )
    }
}

export default Video
