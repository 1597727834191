import React, { Component} from 'react';
import Viewer from 'viewerjs';


class Image extends Component{

    state = {
        html: ''
    }

    componentDidMount() {
        new Viewer(document.getElementById('image'), { //https://github.com/fengyuanchen/viewerjs
            inline: false,
            backdrop: true,
        });
    }

    render() {
        const width = this.props.width === 'maxwidth' ? '100%' : null
        const imageStyle = {
            width,
            cursor: 'pointer'
        }
        return (
            <div className="text-center">
                <img className="file-source" id="image" alt=""
                     width={ this.props.width }
                     height={ this.props.height }
                     src={ this.props.src }
                     style={ imageStyle }
                     onError={({ currentTarget }) => {
                         currentTarget.onerror = null; // prevents looping
                         document.getElementById('file-container').className = 'img-onerror'
                     }}/>
            </div>
        )
    }
}

export default Image

