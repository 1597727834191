import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
    const headerStyle = {
        borderBottom: "1px solid rgba(0,0,0,0.05)"
    }
    const openTab = () => {
        window.open("https://chrome.google.com/webstore/detail/fintest-pro-your-explorat/pdnloikhmiklhhankbkbkfhmlnnkgfmk/");
    }
    return (
        <header>
            <Navbar expand="lg" className="bg-body-tertiary" style={ headerStyle }>
                <Container fluid>
                    <Navbar.Brand href="https://fintest.ca/">
                        <img
                            src={process.env.PUBLIC_URL + '/logo-fintest-pro.png'}
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </Navbar.Brand>
                    <button type="button" className="btn btn-custom" onClick={openTab}>Install for free</button>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;