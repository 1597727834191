
const Footer = () => {
    return (
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 px-4">
                <div className="col-md-4 d-flex align-items-center">
                    <a href="https://fintest.ca/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
                        <img
                            src={process.env.PUBLIC_URL + '/logo128.png'}
                            className="d-inline-block align-top"
                            width="30"
                            alt="logo"
                        />
                    </a>
                    <span className="mb-3 mb-md-0 text-body-secondary">© FINTEST, {new Date().getFullYear()}, ALL RIGHTS RESERVED</span>
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/fintest.ca">
                        <i className="bi bi-facebook"></i>
                    </a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://www.instagram.com/fintestpro">
                        <i className="bi bi-instagram"></i>
                    </a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://www.reddit.com/r/fintest_pro/">
                        <i className="bi bi-reddit"></i>
                    </a></li>
                </ul>
            </footer>
    );
}

export default Footer;