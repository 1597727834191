import React, { Component} from 'react';
import Video from '../../components/video';
import Image from '../../components/image';
import Home from '../../components/home';
import FileCard from '../../components/fileCard';
import { getFiledata } from '../../functions'

class File extends Component{

    state = {
        fileHtml: '',
        cardHtml: ''
    }

    setFileHtml = (data) => {
        const { url, type, width, height } = data

        let filediv = <Home />
        if(type === 'screenshot') {
            const offset = document.getElementById('file-container').offsetWidth
            if(offset > width) filediv = <Image src={ url } width={ width } height={ height } />
            else filediv = <Image src={ url } width={ 'maxwidth' } />
        } else if(type === 'video') {
            filediv = <Video src={url}/>
        }
        this.setState({
            fileHtml: filediv
        })
    }

    setCardHtml = (data) => {
        const { url, urlData } = data
        return getFiledata(urlData)
            .then(resp => {
                if(!resp.success) throw new Error('Something went wrong');
                const { type } = resp.data
                let { displayName, expansion, description, mimeType, width, height } = resp.data.fileData
                if(type === 'video') {
                    expansion = mimeType.split('/')[1]
                }
                this.setState({
                    cardHtml: <FileCard name={`${displayName}.${expansion}`} description={description} />
                })
                return { url, type, width, height }
            })
    }

    componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const v = urlParams.get('v')
        const s = urlParams.get('s')
        const string = v || s
        let link = '/file'

        const param = v ? 'v' : 's', urlData = {}
        urlData[param] = string
        const recursiveEncoded = new URLSearchParams(urlData);
        link += `?${recursiveEncoded}`

        fetch(link)
            .then(resp => resp.json())
            .then(resp => {
                if(!(resp.success && resp.url && resp.urlData)) throw new Error('Something went wrong');
                return this.setCardHtml(resp)
            })
            .then(resp => {
                return this.setFileHtml(resp)
            })
            .catch(() => {
                this.setState({
                    fileHtml: <Image src={ 'error' } />
                })
            })
            .finally(() => {
                document.getElementById('file-loader').remove()
            })
    }

    render() {
        return (
            <div className="mt-5 mb-5">
                <section id="file-container" role="main" className="container">
                    <div id="file-loader" className="d-flex justify-content-center flex-column align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p>This may take a couple of minutes...</p>
                    </div>
                    { this.state.fileHtml }
                    { this.state.cardHtml }
                </section>
            </div>
        )
    }
}

export default File